import React, { useEffect, useState, useCallback } from 'react'

/* Libs and helpers */
import Helmet from 'react-helmet'
import { InView } from 'react-intersection-observer'
import { isMobile } from 'react-device-detect'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { AnimatePresence } from 'framer-motion'
import { scroller } from 'react-scroll'
import debounce from 'lodash.debounce'

/* Layout components */
import Layout from '@components/common/layout'
import Sticky from 'react-stickynode'
import ChooseYourOwn from '@components/scrollytelling/missing-migrants/choose-your-own/index'
import InfographicOverlay from '@components/scrollytelling/missing-migrants/overlays/infographic-overlay'
import CounterComponent from '@components/scrollytelling/missing-migrants/counter'

/* Data imports */
import StorylineData from '@data/missing-migrants/storyline'
import HeaderVideo from '@images/stories/2021/missing-migrants/export-2a.mp4'

const scrollToElement = (element) => {
	if (typeof document === 'undefined') {
		return
	}
	scroller.scrollTo(element, {
		duration: 1000,
		delay: 0,
		smooth: 'easeInOutQuart',
		offset: isMobile ? -140 : -200,
	})
}

const Link = (props) => {
	return (
		<a
			target={props.target || '_blank'}
			href={props.href || '#'}
			className={'font-bold hover:bg-white hover:text-zinc-900 transition-colors duration-100 underline'}
			// style={{ borderBottom: '2px solid white' }}
		>
			{props.children}
		</a>
	)
}

const MissingMigrantsStory = (props) => {
	const [chapter, setChapter] = useState(0)
	const [adventureFocus, setAdventureFocus] = useState(false)
	const [counterStarted, setCounterStarted] = useState(false)
	const [currentVideo, setCurrentVideo] = useState(0)

	const Images = props.data.allFile.edges

	const getImageByName = (name) => {
		return Images.filter((el) => el.node.name === name)[0].node.childImageSharp.gatsbyImageData
	}

	const VIDEOS = ['export-1a.mp4']

	const CHAPTER_HAS_MAP = [
		false, // 0
		false, // 1
		false, // 2
		false, // 3
		false, // 4
		false, // 5
		false, // 6
		false, // 7
		false, // 8
		false, // 9
		false, // 10
		false, // 11
		false, // 12
		false, // 13
		false, // 14
		false, // 15
		false, // 16
		false, // 17
	]

	const changeChapter = (newChapter) => {
		setChapter(newChapter)
	}

	const debouncedchangeChapter = useCallback(debounce(changeChapter, 800), [])
	const debouncedSetCounterStarted = useCallback(debounce(setCounterStarted, 500), [])

	const ArticleTags = () => {
		return (
			<div className={'px-8 sm:px-0 inverted max-w-6xl flex flex-row justify-start items-center w-full mb-8'}>
				<a
					href='https://www.thenewhumanitarian.org/migration'
					title='View more reports on Migration'
					className='p-1 mr-4 font-bold text-zinc-100'
					data-report-theme='Migration'
					style={{
						backgroundColor: '#35b58b',
					}}
				>
					Migration
				</a>

				<a
					href='https://www.thenewhumanitarian.org/special-report'
					title='View more Special Reports'
					className='mr-4 font-bold text-zinc-50 hover:bg-transparent'
				>
					Special Report
				</a>

				<span className={'text-zinc-400'}>16 June 2022</span>
			</div>
		)
	}

	const AuthorInfo = () => {
		return (
			<div className={'px-8 sm:px-0 inverted max-w-6xl flex flex-row justify-start items-center w-full mb-16'}>
				<div
					className={'w-24 h-24'}
					style={{
						background: 'url(https://assets.irinnews.org/s3fs-public/styles/author/public/eric-reidy.jpg?_fW2gqkazKJs3ZPfXHwD8Z.qbWc0lGLe&itok=MBWHw7Fa)',
						backgroundSize: 'cover',
					}}
				/>
				<div className={'text-white ml-6'}>
					<Link href='https://www.thenewhumanitarian.org/authors/eric-reidy' target='_blank'>
						<h3 className={'font-bold'}>Eric Reidy</h3>
					</Link>
					<p>Migration Editor-at-large</p>
				</div>
			</div>
		)
	}

	const VideoHeader = (props) => {
		if (isMobile) {
			return (
				<div>
					<video
						className='absolute z-0 object-cover w-full h-full overflow-hidden opacity-100'
						autoPlay
						muted
						loop
						width={'100%'}
						height={'auto'}
						style={{
							width: '100% !important',
							height: '100%',
							transform: 'translate(-50%, -50%)',
							top: '50%',
							left: '50%',
							objectFit: 'cover !important',
						}}
					>
						<source src={'https://assets.irinnews.org/s3fs-public/export-1a.mp4?P4VxkOAjq_AApYXbuKWszit9OnqJs6g2'} type={'video/mp4'} />
						<source src={'https://media.thenewhumanitarian.org/export-1a.mp4'} type={'video/mp4'} />
						<source src={HeaderVideo} type={'video/mp4'} />
						<source src={require(`@images/stories/2021/missing-migrants/${VIDEOS[currentVideo]}`).default} type={'video/mp4'} />
					</video>
					<div className={'absolute top-0 left-0 w-full h-full flex items-center justify-center flex-col z-1'}>
						<div className={'px-4'}>{props.children}</div>
					</div>
					<p className={'text-lg sm:text-inherit italic opacity-50 absolute bottom-0 sm:bottom-2 w-full text-center left-0'}>
						Design and production by Kylee Pedersen and Marc Fehr.
					</p>
				</div>
			)
		} else {
			return (
				<div className={'z-50'}>
					<video
						className='absolute object-cover min-w-full min-h-screen overflow-hidden opacity-100 h-screen-desktop max-w-none'
						autoPlay
						muted
						loop
						placeholder={'/images/missing-migrants/video-placeholder.webp'}
						style={{
							width: '100% !important',
							height: 'auto',
							transform: 'translate(-50%, -50%)',
							top: '50%',
							left: '50%',
							objectFit: 'cover !important',
						}}
					>
						<source src={'https://assets.irinnews.org/s3fs-public/export-1a.mp4?P4VxkOAjq_AApYXbuKWszit9OnqJs6g2'} type={'video/mp4'} />
						<source src={'https://media.thenewhumanitarian.org/export-1a.mp4'} type={'video/mp4'} />
						<source src={HeaderVideo} type={'video/mp4'} />
						<source src={require(`@images/stories/2021/missing-migrants/${VIDEOS[currentVideo]}`).default} type={'video/mp4'} />
					</video>
					<div className={'absolute top-0 left-0 flex justify-center items-center content-center w-full h-full flex-col'}>
						<div className={'max-w-5xl'}>{props.children}</div>
					</div>
					<p className={'italic opacity-50 absolute bottom-8 w-full text-center left-0'}>Design and production by Kylee Pedersen and Marc Fehr.</p>
				</div>
			)
		}
	}

	const ScrollDownIcon = (props) => {
		return (
			<div className={'w-full flex items-center justify-center h-32'}>
				<div className={'w-16 h-16 animate-ping opacity-30'}>
					<svg xmlns='http://www.w3.org/2000/svg' width='100%' viewBox='0 0 100 100' className={'inline fill-current w-full h-full'}>
						<path fill='none' stroke='#fff' strokeWidth='14' d='M78.285 35.996L50 64.277l-28.285-28.28'></path>
					</svg>
				</div>
			</div>
		)
	}

	const RoundNumber = (props) => {
		return (
			<div
				className={'w-16 h-16 rounded-full bg-zinc-50 text-center flex items-center justify-center sm:absolute sm:top-4 sm:left-4 drop-shadow-2xl mb-8 sm:mb-0'}
			>
				<h2 className={'text-5xl m-0 p-0 text-zinc-900 pb-2'}>{props.number}.</h2>
			</div>
		)
	}

	const MapGridWrapper = (props) => {
		return (
			<div className={`w-full relative`}>
				<div className={'relative w-full'}>
					<div className={'px-8 text-left inverted flow relative'}>{props.children}</div>
				</div>
			</div>
		)
	}

	const MapGridSection = (props) => {
		const bgColorClass = props.sticky ? 'bg-transparent' : 'migrants-gradient'

		return (
			<InView
				as='div'
				triggerOnce={true}
				// delay={isMobile ? 100 : null}
				// trackVisibility={isMobile}
				threshold={1}
				rootMargin={isMobile ? '0px' : '100px'}
				onChange={(inView) => {
					if (inView) {
						if (props.chapter !== chapter) {
							debouncedchangeChapter(props.chapter)
						}
					}
				}}
			>
				<section
					className={`relative rounded min-h-screen/3-5 sm:min-h-screen/2 py-16 sm:py-0 sm:p-6 flex flex-col items-center sm:items-start justify-center transition-opacity duration-500 ${props.chapter === 1 ? 'sm:mt-1/2' : ''
						}${props.chapter === 6 ? 'sm:mb-1/2' : ''}
          ${isMobile ? '' : bgColorClass} `}
					style={{
						opacity: props.chapter === chapter || props.sticky ? 1 : 0.4,
					}}
				>
					{props.children}
				</section>
			</InView>
		)
	}

	return (
		<Layout
			inverted
			bgColor={'bg-zinc-900'}
			shareProps={{
				title: 'The New Humanitarian | Interactive: The European approach to stopping Libya migration',
				url: 'https://www.thenewhumanitarian.org/2021/11/17/Mediterranean-migration-Europe-system-abuse',
				socialTitle: 'Interactive: The European approach to stopping Libya migration',
				socialDescription: 'Explore the abusive and deadly effects of EU and Libyan policies in the central Mediterranean.',
				socialImage: 'https://assets.thenewhumanitarian.org/s3fs-public/styles/responsive_large/public/legacy_s3_root/teaser.png.webp?itok=V60S9YQ8',
				// 'https://interactive.thenewhumanitarian.org/stories/images/missing-migrants/teaser-gif.gif',
				nodeId: 262214,
				updatedTime: '2021-11-18T10:02:00+00:00',
				modiefiedTime: '2021-11-18T10:02:00+00:00',
				publishedTime: '2021-11-18T09:58:36+00:00',
			}}
		>
			<div className={'bg-zinc-900'}>
				<div id='start'>
					<div className={'h-screen/3-5 sm:h-screen-desktop w-full sm:full-screen relative flex flex-col justify-center items-center'}>
						<div className={'px-8 sm:px-0 sm:max-w-5xl text-center inverted overflow-hidden w-full'}>
							<div>
								<VideoHeader>
									<h1
										className={'mb-6 z-50 px-3 py-2 inline-block rounded mx-auto'}
										style={{
											textShadow: '0px 3px 15px #000',
										}}
									>
										Interactive: The European approach to stopping Libya migration
									</h1>
									<p
										className={'z-50'}
										style={{
											textShadow: '0px 3px 15px #000',
										}}
									>
										Explore the abusive and deadly effects of EU and Libyan policies in the central Mediterranean.
									</p>
								</VideoHeader>
							</div>
						</div>
					</div>
					<div id='story-start' className={'bg-zinc-900 w-full py-8 sm:py-24 relative flex flex-col justify-center items-center z-30 transition-opacity'}>
						<AuthorInfo />
						<ArticleTags />
						<div
							className={'px-8 sm:px-0 text-left inverted flow max-w-6xl field-name-body'}
							style={{
								opacity: adventureFocus ? 0.4 : 1,
							}}
						>
							<p>
								Every day last year, more than four people on average died attempting to cross the central Mediterranean from North Africa to Europe, and around
								90 were intercepted by the {/* <br /> */}
								EU-supported Libyan Coast Guard – returned to detention centres where they{' '}
								<Link href='https://www.thenewhumanitarian.org/news-feature/2021/6/24/libya-fails-to-stop-migrant-detention-abuses-as-eu-backed-returns-soar'>
									face a cycle
								</Link>{' '}
								of torture, extortion, and{' '}
								<Link href={'https://www.thenewhumanitarian.org/news-feature/2021/7/27/women-rescued-at-sea-reveal-scale-of-alleged-libya-abuses'}>
									sexual abuse
								</Link>
								.
							</p>

							<p>
								This year, with crossings guaranteed to increase in the warm summer months, more than{' '}
								<Link href='https://twitter.com/IOM_Libya/status/1536382516206129153?s=20&t=GNWleZC_j3ysQCKnCixXGw'>8,200</Link> people have already been
								intercepted by the Libyan Coast Guard and nearly{' '}
								<Link href='https://missingmigrants.iom.int/region/mediterranean?region_incident=All&route=3861&year%5B%5D=10121&month=All&incident_date%5Bmin%5D=&incident_date%5Bmax%5D='>
									700
								</Link>{' '}
								have died or gone missing at sea.
							</p>

							<p>
								Year after year, the deaths and interception follow a well-established, predictable pattern. But instead of saving lives or protecting the human
								rights of asylum seekers and migrants, “European countries have engaged in a race to the bottom to keep people in need of our protection outside
								our borders,” Dunja Mijatović, the Council of Europe’s Commissioner for Human Rights, wrote in a{' '}
								<Link href='https://www.coe.int/en/web/commissioner/-/european-countries-must-urgently-change-their-migration-policies-which-endanger-refugees-and-migrants-crossing-the-mediterranean'>
									March 2021 report
								</Link>
								.
							</p>

							<InView
								className={`text-left inverted mt-15 sm:mt-16 flow w-full`}
								as='div'
								threshold={0.4}
								onChange={(inView) => {
									if (inView) {
										debouncedSetCounterStarted(true)
									} else {
										debouncedSetCounterStarted(false)
									}
								}}
							>
								<CounterComponent started={counterStarted} />
							</InView>

							<p>
								That race has involved withdrawing European navy and coast guard assets from rescue activities in the central Mediterranean, obstructing the
								operations of rescue NGOs, and funding the implementations of border management projects in Libya and Tunisia aimed at preventing people from
								crossing the sea.
							</p>
							<p>
								The system that has been created by this process is “one of the most glaring examples of how bad migration policies undercut human rights law
								and have cost the lives of thousands of human beings”, according to Mijatović’s report.
							</p>
							<p>
								Asked for comment, Peter Stano, the European Commission’s lead spokesperson for foreign affairs, told The New Humanitarian via email: “Our top
								priority is saving lives at sea and we will continue our work to prevent these risky journeys from taking place.”
							</p>
							<p>
								Below, we show how straightforward search and rescue at sea can be. Then, explore the interactive storyline to see how the EU-backed migration
								control system in the central Mediterranean facilitates more interceptions by the Libyan Coast Guard and reduces search and rescue capacity –
								increasing the likelihood of shipwrecks and deaths.
							</p>
							<h2 className={'pb-16 sm:pt-16 sm:text-6xl md:text-7xl sm:-mx-0 md:-mx-0 lg:-mx-72 text-center'}>
								How maritime search and rescue is supposed to work:
							</h2>

							<div className={'relative px-4 md:px-0 sm:-mx-0 md:-mx-24 lg:-mx-48 xl:-mx-64 my-24'}>
								<GatsbyImage image={getImageByName('flowchart-1')} objectFit={'cover'} className={'w-full'} alt='No alt text found.' />
							</div>
						</div>
					</div>
					{/* Choose your own */}
					<div className={'w-full relative flex flex-col justify-center items-center'}>
						<div className={'mb-16 sm:mb-0 px-8 sm:px-0 text-left inverted flow max-w-6xl field-name-body'}>
							<h2 className={'mb-16 sm:pt-24 sm:text-6xl md:text-7xl sm:-mx-0 md:-mx-0 lg:-mx-72 text-center'}>
								Now, explore this interactive storyline to see how the EU-backed migration control system in the central Mediterranean responds to boats in
								distress:
							</h2>
						</div>
					</div>

					{/* Start choose your own */}
					<div className={'relative flex flex-col justify-center items-center'}>
						<InView
							className={`text-left inverted flow w-full`}
							as='div'
							threshold={isMobile ? 0.4 : 0.75}
							onChange={(inView) => {
								if (inView) {
									setAdventureFocus(true)
								} else {
									setAdventureFocus(false)
								}
							}}
						>
							<div
								style={{
									opacity: adventureFocus ? 1 : 0.4,
								}}
								className={'transition-opacity min-h-screen min-h-screen-ios sm:min-h-0 w-full'}
							>
								<ChooseYourOwn data={StorylineData} getImage={getImageByName} />
								<p className={'sm:block opacity-50 italic text-left p-4 text-2xl sm:w-1/2'}>
									Distress situations at sea are complex, and several of these scenarios can play out simultaneously.
								</p>
							</div>
						</InView>
					</div>
					{/* End choose your own */}

					<div id='story-start-2' className={'bg-zinc-900 w-full py-8 relative flex flex-col justify-center items-center z-30 transition-opacity'}>
						<div className={'px-8 sm:pt-32 sm:px-0 text-left inverted flow max-w-6xl field-name-body'}>
							<h2>How did we get here?</h2>
							<p>
								The situation in the central Mediterranean is “not a tragic anomaly”, according to a{' '}
								<Link href={'https://reliefweb.int/report/libya/lethal-disregard-search-and-rescue-and-protection-migrants-central-mediterranean-sea'}>
									recent report
								</Link>{' '}
								by the Office of the United Nations High Commissioner for Human Rights (OHCHR), “but rather a consequence of concrete policy decisions and
								practices by the Libyan authorities, European Union (EU) Member States and institutions, and other actors”.{' '}
							</p>
							<p>
								At the height of the Mediterranean migration crisis in November 2015, EU member states laid out an{' '}
								<Link href={'https://www.consilium.europa.eu/media/21841/political_decl_en.pdf'}>Action Plan</Link> for “better migration management” during a
								summit between European and African countries in Malta, and launched a funding mechanism – the{' '}
								<Link href={'https://ec.europa.eu/trustfundforafrica/index_en'}>EU Trust Fund for Africa</Link> – to finance its goals.
							</p>
							<p>
								As one of its priorities, the plan called for EU member states and institutions to improve countries’ abilities to “control land, sea and air
								borders as well as maritime surveillance capabilities for the purpose of prevention of irregular migration”.
							</p>
						</div>
					</div>

					{/* Map grid with two cols */}
					<div
						className={`mt-32 sm:mt-0 relative w-full flex flex-col md:grid md:grid-cols-2 text-zinc-300 shadow-none sm:shadow-none`}
						style={{
							opacity: adventureFocus ? 0.4 : 1,
						}}
						id={'map-start-1'}
					>
						<Sticky top={isMobile ? 8 : 80} innerZ={999} bottomBoundary={'#map-start-1'} activeClass={'sm:pt-32'}>
							<div
								className={`relative left-0 top-0 w-full h-0 pt-fourbythree sm:h-screen-desktop p-0 sm:p-0 shadow-2xl sm:shadow-none sm:bg-transparent z-50 ${chapter < 1 ? 'opacity-100' : ''
									}`}
							>
								<AnimatePresence>
									<InfographicOverlay chapter={chapter} key={`infographic-overlay-${chapter}`} images={props.data.allFile.edges} />
								</AnimatePresence>
							</div>
						</Sticky>

						{/* Map grid section 1 */}

						<div
							className={`flow inverted bg-transparent z-1 mt-20 sm:mt-0 md:-mt-14 transition-opacity`}
							style={{
								opacity: adventureFocus ? 0.4 : 1,
							}}
						>
							<MapGridWrapper>
								<MapGridSection chapter={0} sticky>
									<h2 className={'sm:pt-56'}>
										To implement this objective in Libya, the EU and EU member states – especially Italy – have spent tens of millions of euros in recent years
										to:
									</h2>
								</MapGridSection>

								<ScrollDownIcon />

								<MapGridSection chapter={1}>
									<RoundNumber number={1} />
									<p className={'sm:text-4xl md:text-4xl lg:text-4xl leading-snug sm:leading-loose sm:py-24'}>
										Provide{' '}
										<Link href={'https://www.thenewhumanitarian.org/analysis/2020/08/10/Libya-migrant-abuses-EU-legal-battle'}>
											patrol boats, training, and support
										</Link>{' '}
										to the Libyan Coast Guard to intercept asylum seekers and migrants at sea – including conducting repairs on donated Libyan Coast Guard boats
										when they need maintenance.
									</p>
								</MapGridSection>

								<ScrollDownIcon />

								<MapGridSection chapter={2}>
									<RoundNumber number={2} />
									<p className={'sm:text-4xl md:text-4xl lg:text-4xl leading-snug sm:leading-loose sm:py-24'}>
										Guide Libyan authorities through the process of declaring a search and rescue region in the central Mediterranean where Libya has primary
										responsibility for coordinating the response to boats in distress – this was given{' '}
										<Link href={'https://www.euronews.com/2018/07/06/prompted-by-eu-libya-quietly-claims-right-to-order-rescuers-to-return-fleeing-migrants'}>
											international recognition
										</Link>{' '}
										in June 2018.
									</p>
								</MapGridSection>

								<ScrollDownIcon />

								<MapGridSection chapter={3}>
									<RoundNumber number={3} />
									<p className={'sm:text-4xl md:text-4xl lg:text-4xl leading-snug sm:leading-loose sm:py-24'}>
										Establish a Libyan Rescue Coordination Centre to oversee activities in Libya’s newly declared search and rescue region. Despite the support,
										human rights groups, NGOs, and{' '}
										<Link
											href={
												'https://www.spiegel.de/international/europe/libya-how-frontex-helps-haul-migrants-back-to-libyan-torture-camps-a-d62c3960-ece2-499b-8a3f-1ede2eaefb83'
											}
										>
											journalists
										</Link>{' '}
										say the official Libyan rescue coordination centre doesn’t actually exist and the Libyan Coast Guard instead receives information about
										boats at sea via a poorly equipped command centre at a military base in Tripoli.
									</p>
								</MapGridSection>

								<ScrollDownIcon />

								<MapGridSection chapter={0} sticky>
									<h2>
										Since the declaration of the Libyan search and rescue region, the EU and EU member states have helped facilitate the Libyan Coast Guard’s
										interception of boats in three main ways:
									</h2>
								</MapGridSection>
								<MapGridSection chapter={4}>
									<RoundNumber number={1} />
									<p className={'sm:text-4xl md:text-4xl lg:text-4xl leading-snug sm:leading-loose sm:py-24'}>
										They have gradually{' '}
										<Link href={'https://reliefweb.int/report/libya/lethal-disregard-search-and-rescue-and-protection-migrants-central-mediterranean-sea'}>
											withdrawn
										</Link>{' '}
										their naval and search and rescue assets in the central Mediterranean and shifted responsibility for coordinating responses to boats in
										distress to the Libyan Coast Guard.
									</p>
								</MapGridSection>

								<ScrollDownIcon />

								<MapGridSection chapter={5}>
									<RoundNumber number={2} />
									<p className={'sm:text-4xl md:text-4xl lg:text-4xl leading-snug sm:leading-loose sm:py-24'}>
										The EU border agency, FRONTEX, has become increasingly active at conducting aerial surveillance to detect the departure of boats carrying
										asylum seekers and migrants from Libya – at first using airplanes, and switching to high-altitude drones starting in May this year.
									</p>
								</MapGridSection>

								<ScrollDownIcon />

								<MapGridSection chapter={6}>
									<RoundNumber number={3} />
									<p className={'sm:text-4xl md:text-4xl lg:text-4xl leading-snug sm:leading-loose sm:py-24'}>
										EU member states have{' '}
										<Link href={'https://fra.europa.eu/en/publication/2021/december-2021-update-ngo-ships-sar-activities'}>obstructed</Link> the operations of
										search and rescue NGOs through criminal investigations and administrative procedures that have made it difficult for them to maintain a
										presence at sea. The most recent{' '}
										<Link href={'https://fra.europa.eu/en/publication/2021/december-2021-update-ngo-ships-sar-activities'}>audit</Link> by the EU’s Agency for
										Fundamental Rights, in December 2021, found that since 2016 around{' '}
										<Link href={'https://fra.europa.eu/sites/default/files/2021-12/Table%202%20Criminalisation%20NGO%20SAR%20up%20to%20December%202021.pdf'}>
											35
										</Link>{' '}
										legal proceedings have been initiated against boats that operate in the central Mediterranean, obstructing their ability to perform rescues
										for varying periods of time.
									</p>
								</MapGridSection>
							</MapGridWrapper>
						</div>
					</div>

					{/* Back to paragraph layout */}

					<div className={'w-full relative flex flex-col justify-center items-center pb-32'}>
						<div className={'px-8 sm:px-0 mt-8 sm:mt-0 text-left inverted flow max-w-6xl field-name-body'}>
							<p className={'hidden'} />
							<p>
								Stano, the European Commission’s spokesperson, said: “It is unfair and actually incorrect to blame the European Union for the suffering of the
								migrants trying to cross the Mediterranean from Libya” – pointing instead to smugglers and traffickers who exploit asylum seekers and migrants.
							</p>
							<p>
								But <Link href={'https://www.amnesty.eu/wp-content/uploads/2020/09/Libya-report-Between-life-and-death.pdf'}>human rights</Link>{' '}
								<Link href={'https://www.hrw.org/report/2019/01/21/no-escape-hell/eu-policies-contribute-abuse-migrants-libya'}>watchdogs</Link>,{' '}
								<Link href={'https://www.europarl.europa.eu/thinktank/en/document.html?reference=IPOL_STU(2021)694413'}>researchers</Link> commissioned by the
								EU Parliament, search and rescue NGOs, and{' '}
								<Link
									href={
										'https://www.theguardian.com/global-development/2021/jun/18/eu-has-blood-on-its-hands-say-activists-calling-for-frontex-border-agencys-abolition'
									}
								>
									activists
								</Link>{' '}
								say Italy, Malta, and the EU border agency seek to make Libyan authorities the first point of contact when boats depart for Europe, cutting
								civilian rescuers – when they’re able to get to sea – out of communication channels in most cases.
							</p>
							<p>
								European countries are also slow – and sometimes fail – to mobilise their own search and rescue assets to assist boats in distress. When it
								comes to Malta, this is true even when boats have entered the Maltese search and rescue region.
							</p>
							<p>
								As a result, boats of migrants and asylum seekers are being intercepted more often by the Libyan Coast Guard – which has been documented opening
								fire at, ramming, and capsizing boats it is supposed to rescue.
							</p>
							<p>
								Those intercepted are pulled back to Libya, where they are{' '}
								<Link href={'https://www.thenewhumanitarian.org/news-feature/2021/6/24/libya-fails-to-stop-migrant-detention-abuses-as-eu-backed-returns-soar'}>
									automatically detained{' '}
								</Link>{' '}
								in official centres{' '}
								<Link href={'https://www.thenewhumanitarian.org/news-feature/2021/7/27/women-rescued-at-sea-reveal-scale-of-alleged-libya-abuses'}>
									rampant with abuse
								</Link>
								, or{' '}
								<Link href={'https://www.thenewhumanitarian.org/news-feature/2020/08/05/missing-migrants-Libya-forced-returns-Mediterranean'}>
									disappeared by the thousands
								</Link>{' '}
								into shadowy, semi-official facilities where they are beyond the reach of international organisations. The boats that are not intercepted are
								left to spend a longer time at sea, increasing the likelihood of shipwrecks and of people dying from exposure to the elements.
							</p>
							<p>
								Since 2017, when the EU and its member states began implementing the action plan for “better migration management”, more than 92,000 people have
								been intercepted by the EU-backed Libyan Coast Guard, and more than 8,600 others have died or disappeared in the central Mediterranean.
							</p>
							<br />
							<br />
							<br />
							<hr />
							<p className={'italic opacity-50'}>
								Arrival, interception, and death statistics come from <Link href={'https://data.unhcr.org/en/documents/details/89486'}>UNHCR</Link> and{' '}
								<Link href={'https://missingmigrants.iom.int/region/mediterranean?region_incident=All&route=3861&year%5B%5D=2500&month=All'}>IOM</Link> and
								represent totals as of 15 November 2021.
							</p>
							<p className={'italic opacity-50'}>(This is a republication of a story initially published on 17 November 2021.)</p>
						</div>
					</div>
				</div>
				<div className={'p-8 flex items-center justify-center cursor-pointer'} onClick={() => scrollToElement('story-start')}>
					<p>↑ Scroll to top</p>
				</div>
			</div>
		</Layout>
	)
}

export const query = graphql`
	query MigrantsImages {
		allFile(
			filter: { sourceInstanceName: { eq: "images" }, extension: { in: ["jpg", "png", "jpeg"] }, relativePath: { glob: "stories/2021/missing-migrants/*" } }
		) {
			edges {
				node {
					extension
					dir
					modifiedTime
					name
					relativePath
					childImageSharp {
						gatsbyImageData(
							width: 850
							quality: 85
							placeholder: BLURRED
						)
					}
				}
			}
		}
	}
`

export default MissingMigrantsStory
