import React from 'react'
import { motion } from 'framer-motion'

import { isMobile } from 'react-device-detect'

/* Import components */
import InfographicContent from './infographic-content'

const InfographicOverlay = ({ className, chapter, images }) => {
  const containerVariants = {
    initial: {
      opacity: 0,
      // y: -50,
      transition: {
        // type: 'spring',
        // stiffness: 100,
        duration: 0.4,
        delay: 0.2,
      },
    },
    exit: {
      // opacity: [1, 0.3, 0.3, 0.1, 0],
      opacity: 1,
      // y: 0,
      transition: {
        // type: 'spring',
        // stiffness: 100,
        duration: 0.4,
        delay: 0.2,
      },
    },
    intro: {
      // opacity: [0, 0.1, 0.3, 0.3, 1],
      opacity: 1,
      // y: 0,
      transition: {
        // type: 'spring',
        // stiffness: 100,
        duration: 0.4,
        delay: 0.2,
      },
    },
  }

  const mobileStyles = {
    width: 'calc(100% - 1.5rem)',
    height: 'calc(100% - 1.5rem',
    top: '0.75rem',
    left: '0.75rem',
  }

  return (
    <motion.div
      variants={containerVariants}
      initial={'initial'}
      animate={'intro'}
      exit={'exit'}
      className={`text-gray-50 w-full h-full absolute top-0 left-0 z-50 flex justify-center items-center flex-col ${className}`}
    >
      <div
        className={
          'flex z-50 sm:p-3 absolute sm:top-0 sm:left-0 w-full h-full'
        }
      >
        <InfographicContent chapter={chapter} images={images} />
      </div>
    </motion.div>
  )
}

export default InfographicOverlay
