import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { isMobile } from 'react-device-detect'

const InfographicContent = ({ chapter, images }) => {
  const [isMobileSSR, setIsMobileSSR] = React.useState(false)

  React.useEffect(() => {
    setIsMobileSSR(isMobile)
  }, [])

  const getChapterImage = () => {
    const imageName = isMobileSSR ? `mobile-${chapter}` : `desktop-${chapter}`
    return images.filter((el) => el.node.name === imageName)[0].node
      .childImageSharp.gatsbyImageData
  }

  const CHAPTERS = [
    'img', // 0
    'img', // 1
    'img', // 2
    'img', // 3
    'img', // 4
    'img', // 5
    'img', // 6
    'img', // 7
    'img', // 8
    'img', // 9
    'img', // 10
    'img', // 11
    'img', // 12
    'img', // 13
    'img', // 14
    'img', // 15
    'img', // 16
    'img', // 17
  ]

  return (
    // <div className={'absolute top-0 left-0 w-full h-full migrants-gradient'}>
    <div className={'absolute top-0 left-0 w-full h-full'}>
      {CHAPTERS[chapter] === 'img' && (
        <>
          <GatsbyImage
            image={getChapterImage()}
            // objectFit={'cover'}
            objectFit={'contain'}
            className={'w-full h-full absolute top-0 left-0'}
            alt='No alt text found.'
          />
        </>
      )}
      {/* <p className={'text-gray-50'}>Caption / Credit etc.</p> */}
    </div>
  )
}

export default InfographicContent
