import React, { useEffect, useRef, useState } from 'react'
import { animate } from 'framer-motion'
// import moment from 'moment'

function Counter({ from, to, duration, delay, text }) {
	const nodeRef = useRef()

	const numberWithCommas = (x) => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
	}

	const [showDescription, setShowDescription] = useState(false)

	useEffect(() => {
		const node = nodeRef.current

		const controls = animate(from, to, {
			duration: duration || 10,
			// ease: 'easeOut',
			damping: 10,
			mass: 0.75,
			stiffness: 100,
			delay: delay || 0,
			onUpdate(value) {
				node.textContent = numberWithCommas(value.toFixed(0))
			},
			onComplete(value) {
				setShowDescription(true)
			},
			onPlay(value) {
				setShowDescription(false)
			},
		})

		return () => controls.stop()
	}, [from, to])

	return (
		<div className={'flex items-center justify-center flex-col py-4'}>
			{text && <p className={'divide-y-4'}>{text}</p>}
			<div
				className={`text-center pt-6 m-0 text-8xl flex items-center justify-center font-bold transition-colors duration-1000 ${
					showDescription ? 'text-burgundy' : ''
				}`}
				ref={nodeRef}
			/>
		</div>
	)
}

const CounterComponent = ({ started }) => {
	return (
		<div className={'sm:pt-8 sm:pb-10 bg-zinc-600 my-14'}>
			<div className={'bg-zinc-700 p-8 relative sm:-mx-48 grid grid-cols-1 sm:grid-cols-2 shadow-lg'}>
				<div>
					<Counter
						from={0}
						to={started ? 1553 : 0}
						duration={2.5}
						delay={0}
						text={'Died/missing in 2021'}
					/>
				</div>
				<div>
					<Counter
						from={0}
						to={started ? 32425 : 0}
						duration={5}
						delay={0}
						text={'Intercepted & returned in 2021'}
					/>
				</div>
			</div>
			{/* <div className={''}>
        <p className={'text-center mt-10 italic'}>
          All numbers are based on estimates for {dateNowFormatted}
        </p>
      </div> */}
		</div>
	)
}

export default CounterComponent
