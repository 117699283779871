import React, { useState } from 'react'

import { GatsbyImage } from 'gatsby-plugin-image'
import { motion, LayoutGroup } from 'framer-motion'
import { scroller } from 'react-scroll'
import { isMobile } from 'react-device-detect'

import AdventureCard from '@components/scrollytelling/missing-migrants/choose-your-own/adventure-card'

const ArrowLeft = () => {
  return (
    <span className={'w-5 h-5 text-gray-900 inline-block'}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='100%'
        viewBox='0 0 100 100'
        stroke='currentColor'
        className={'w-full inline fill-current'}
      >
        <path d='M87.5 45.832H28.75l17.918-17.914-5.836-5.836L12.918 50l27.914 27.918 5.836-5.836L28.75 54.168H87.5z'></path>
      </svg>
    </span>
  )
}

const ChooseYourOwn = (props) => {
  const [currentCard, setCurrentCard] = useState(0)
  const [cardStack, setCardStack] = useState([0])

  const { data } = props

  const changeChapter = (newCard) => {
    /* Set currently active card to the new one */
    setCurrentCard(newCard)
    setCardStack((prevState) => [...prevState, newCard])
    if (isMobile) {
      scroller.scrollTo('choose-your-own', {
        duration: 100,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -60,
      })
    }
  }

  const undoCard = () => {
    /* Go back one card by popping the array */
    if (cardStack.length > 1) {
      setCardStack((prevState) => prevState.slice(0, -1))
      if (isMobile) {
        scroller.scrollTo('choose-your-own', {
          duration: 100,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -60,
        })
      }
    }
  }

  const restartStack = () => {
    /* Restart by setting cardStack to 0 */
    setCardStack([0])
  }

  const currentData = data.filter(
    (el) => el.id === cardStack[cardStack.length - 1]
  )[0]

  // const renderText = ({ text }) => {
  //   if (text.includes('<a')) {
  //     return renderHTML(text)
  //   } else {
  //     return text
  //   }
  // }

  return (
    <div className={'flow overflow-hidden'}>
      {/* <div className={'pb-8 text-center'}>
        <h1>Choose your own adventure...</h1>
        <p>Click the different white boxes to decide where this is going.</p>
      </div> */}

      <div
        className={
          'relative w-full flex flex-col md:grid md:grid-cols-2 items-start'
        }
      >
        <LayoutGroup>
          <motion.div
            id='choose-your-own'
            className={`relative w-full pt-fourbythree overflow-hidden sm:mt-24 ${
              currentData.image ? 'bg-transparent' : 'migrants-gradient'
            }`}
            // style={{ backgroundColor: currentData.bgColor || 'transparent' }}
          >
            <div
              className={
                'absolute top-0 left-0 w-full h-full flex items-center justify-center flex-col'
              }
            >
              {currentData.image && (
                <>
                  <GatsbyImage
                    image={props.getImage(currentData.image)}
                    objectFit={'contain'}
                    className={'w-full absolute top-0 left-0 h-full'}
                    alt={currentData.text || 'No alt text set.'}
                  />
                </>
              )}
              {currentData.imageCredit && (
                <p
                  className={
                    'absolute bottom-0 left-0 px-3 py-1 bg-gray-900 bg-opacity-50 opacity-80'
                  }
                >
                  {currentData.imageCredit}
                </p>
              )}
              {currentData.text && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<p class='migrants-textbox p-4 font-bold text-center text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl sm:leading-normal'>${currentData.text}</p>`,
                  }}
                />
              )}
            </div>
          </motion.div>
        </LayoutGroup>
        <div className={'w-full'}>
          {/* <hr /> */}
          <div className={'flex flex-row justify-center items-center sm:mt-2'}>
            <button
              className={`w-1/2 sm:w-auto px-8 py-5 font-bold inline-block text-gray-50 sm:hover:text-gray-50 sm:hover:bg-burgundy ${
                cardStack.length > 1 ? '' : 'opacity-20 cursor-not-allowed'
              }`}
              onClick={() => undoCard()}
            >
              <ArrowLeft />
              Undo
            </button>
            <button
              className={`w-1/2 sm:w-auto sm:bg-gray-800 px-8 py-5 font-bold inline-block text-gray-50 sm:hover:bg-opacity-80 ${
                cardStack.length > 1 ? '' : 'opacity-20 cursor-not-allowed'
              }`}
              onClick={() => restartStack()}
            >
              Restart
            </button>
          </div>
          <LayoutGroup>
            <motion.div
              // layout
              key={`question-card-${currentCard}`}
              initial={{ opacity: 0, y: -50, scale: 1 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: 50, scale: 1 }}
              transition={{
                duration: 0.1,
              }}
              className={`mt-0 pb-4 sm:pb-0 overflow-hidden`}
            >
              <AdventureCard data={currentData} clickHandler={changeChapter} />
            </motion.div>
          </LayoutGroup>
        </div>
      </div>
    </div>
  )
}

export default ChooseYourOwn
