import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { scroller } from 'react-scroll'
import { isMobile } from 'react-device-detect'

const AdventureCard = (props) => {
	const { data } = props

	const chooseAnswer = (i) => {
		const chosenAnswer = data.answers[i]
		props.clickHandler(chosenAnswer.trigger)
		if (isMobile) {
			if (typeof document === 'undefined') {
				return
			}
			scroller.scrollTo('choose-your-own', {
				duration: 100,
				delay: 0,
				smooth: 'easeInOutQuart',
				offset: -60,
			})
		}
	}

	const visibleAnswers = data.answers

	return (
		<div className={`relative w-full overflow-hidden`}>
			<div className={`flex flex-col justify-center sm:justify-start items-start p-4 rounded-sm w-full flex-wrap`}>
				<AnimatePresence>
					{visibleAnswers.length > 0 &&
						visibleAnswers.map((answer, i) => {
							return (
								<motion.div
									layout
									initial={{ opacity: 0, y: -15 }}
									animate={{ opacity: 1, y: 0 }}
									exit={{ opacity: 0, y: -15 }}
									transition={{
										duration: 0.25 + i * (0.25 / data.answers.length),
										ease: 'easeInOut',
									}}
									key={`question-${data.id}-answer-${i}`}
									onClick={() => chooseAnswer(i)}
									className={'flex justify-center w-full mt-3'}
								>
									<p
										className={`block my-0 w-full min-w-full text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-4xl font-bold px-5 py-4 sm:px-8 sm:py-7 cursor-pointer ${
											data.isEnd
												? 'migrants-gradient text-gray-50 sm:text-3xl hover:text-gray-200 hover:underline'
												: 'bg-gray-50 text-gray-900 hover:bg-gray-400'
										} text-left rounded-sm`}
									>
										{answer.text}
									</p>
								</motion.div>
							)
						})}
				</AnimatePresence>
			</div>
		</div>
	)
}

export default AdventureCard
