const data = [
	{
		id: 0,
		image: 'CYOA-000-1200',
		imageCredit: 'Sara Creta/TNH',
		bgColor: 'transparent',
		isEnd: false,
		// text: 'After a boat departs from Libya, three things can happen:',
		answers: [
			{
				id: '001',
				text: 'The boat is spotted',
				trigger: 101,
			},
			{
				id: '002',
				text: 'The boat makes a distress call',
				trigger: 102,
			},
			{
				id: '003',
				text: 'Neither of the above',
				trigger: 103,
			},
		],
	},
	{
		id: 101,
		image: 'CYOA-cards-101',
		imageCredit: 'Credit/TNH',
		bgColor: 'transparent',
		isEnd: false,
		// text: 'The EU and EU member states use airplanes and drones to survey the area off of Libya’s coast where most asylum seekers and migrants depart. The Libyan Coast Guard patrols the Libyan search and rescue zone, and search and rescue NGOs conduct missions in international waters off the coast of Libya and operate several reconnaissance airplanes that search for boats in distress. Fishing, merchant, and recreational vessels also work and travel in the central Mediterranean. Who spots the boat?',
		answers: [
			{
				id: 201,
				text: 'An EU or EU member state surveillance asset',
				trigger: 301,
			},
			{
				id: 202,
				text: 'The Libyan Coast Guard',
				trigger: 302,
			},
			{
				id: 203,
				text: 'An NGO search and rescue boat',
				trigger: 303,
			},
			{
				id: 204,
				text: 'An NGO reconnaissance airplane',
				trigger: 304,
			},
			{
				id: 205,
				text: 'A merchant, fishing, or recreational vessel',
				trigger: 305,
			},
		],
	},
	{
		id: 102,
		image: 'CYOA-cards-102',
		// imageCredit: 'Credit/TNH',
		bgColor: 'transparent',
		isEnd: false,
		// text: 'Coastal states are supposed to have Maritime Rescue Coordination Centres (RCC) to monitor for boats in distress and coordinate rescue operations. Italy and Malta are the closest EU countries to Libya and often receive distress calls from boats of asylum seekers and migrants. The NGO Alarm Phone also operates a hotline for emergency calls and puts pressure on authorities to respond to distress cases. Who does the boat contact?',
		answers: [
			{
				id: 206,
				text: 'The Italian or Maltese RCC',
				trigger: 306,
			},
			{
				id: 207,
				text: 'Alarm Phone',
				trigger: 307,
			},
		],
	},
	{
		id: 103,
		image: 'CYOA-cards-103',
		// imageCredit: 'Credit/TNH',
		bgColor: 'transparent',
		isEnd: false,
		// text: 'If a boat isn’t spotted and doesn’t make a distress call, there are two options: either it arrives in Italy or Malta on its own; or something goes wrong – it runs out of fuel, the engine breaks down, weather conditions deteriorate – and a shipwreck occurs. What happens next?',
		answers: [
			{
				id: 208,
				text: 'The boat arrives in Italy or Malta',
				trigger: 308,
			},
			{
				id: 208,
				text: 'A shipwreck occurs',
				trigger: 309,
			},
		],
	},
	{
		id: 301,
		image: 'CYOA-cards-301',
		imageCredit: 'Sara Creta/TNH',
		bgColor: 'transparent',
		isEnd: false,
		// text: 'In May this year, the EU border agency, Frontex, began using high altitude drones in the central Mediterranean to detect boats departing from Libya. EU member states and naval missions also use airplanes and drones to conduct surveillance. Human rights watchdogs say Frontex and EU member states have made Libyan authorities the first point of contact when boats are detected and have cut civilian rescuers out of communication channels in most cases. What happens when Frontex or EU member states contact Libyan authorities?',
		answers: [
			{
				id: 401,
				text: 'Libyan authorities respond',
				trigger: 501,
			},
			{
				id: 402,
				text: 'Libyan authorities don’t respond',
				trigger: 502,
			},
		],
	},
	{
		id: 302,
		image: 'CYOA-cards-302',
		imageCredit: 'Sara Creta/TNH',
		bgColor: 'transparent',
		isEnd: false,
		// text: 'The Libyan Coast Guard has become increasingly active at intercepting boats and returning people to Libya since the EU and EU member states began providing it with funding, equipment, and training in 2016.',
		answers: [
			{
				id: 403,
				text: 'What happens when people are returned to Libya?',
				trigger: 503,
			},
		],
	},
	{
		id: 303,
		image: 'CYOA-cards-303',
		imageCredit: 'Sara Creta/TNH',
		bgColor: 'transparent',
		isEnd: false,
		// text: 'NGO vessels often rescue hundreds of asylum seekers and migrants at a time. The next step can be slow: Malta does not allow NGOs to disembark rescued people in its ports; and Italy often takes days – and sometimes longer – to give NGO boats permission to dock. The delay exacerbates psychological trauma and often delays urgent medical care',
		answers: [
			{
				id: 606,
				text: 'What happens after disembarkation?',
				trigger: 504,
			},
		],
	},
	{
		id: 304,
		image: 'CYOA-cards-304',
		imageCredit: 'Sara Creta/TNH',
		bgColor: 'transparent',
		isEnd: false,
		// text: 'Three NGO aircraft fly missions above international waters off the Libyan coast. When they find distress cases they can contact NGO search and rescue boats, the Italian and Maltese RCCs, or nearby merchant or fishing vessels that might be able to provide assistance. When no other assets are nearby and there is an immediate need for assistance – such as a capsized boat – they may also notify the Libyan Coast Guard. Who does the NGO reconnaissance plane contact?',
		answers: [
			{
				id: 405,
				text: 'An NGO search and rescue boat',
				trigger: 303,
			},
			{
				id: 406,
				text: 'The Italian or Maltese RCC',
				trigger: 306,
			},
			{
				id: 407,
				text: 'A merchant, fishing, or private vessel',
				trigger: 305,
			},
			{
				id: 408,
				text: 'The Libyan Coast Guard',
				trigger: 302,
			},
		],
	},
	{
		id: 305,
		image: 'CYOA-cards-305',
		imageCredit: 'Amr Abdallah Dalsh/REUTERS',
		bgColor: 'transparent',
		isEnd: false,
		// text: '<a href="https://www.thenewhumanitarian.org/news-feature/2021/6/2/a-sicilian-fishing-town-and-the-perils-of-italys-migration-deal-with-libya">Fishing grounds</a>, shipping lanes, oil platforms, and recreation bring private boats into the central Mediterranean. However, captains of private vessels face a dilemma when they spot a boat of asylum seekers and migrants or are called on to provide assistance. Do they:',
		answers: [
			{
				id: 409,
				text: 'Ignore the boat',
				trigger: 505,
			},
			{
				id: 410,
				text: 'Rescue the passengers',
				trigger: 506,
			},
			{
				id: 411,
				text: 'Coordinate with the Libyan Coast Guard',
				trigger: 507,
			},
		],
	},
	{
		id: 306,
		image: 'CYOA-cards-306',
		imageCredit: 'REUTERS',
		bgColor: 'transparent',
		isEnd: false,
		// text: 'When the Italian and Maltese RCCs are contacted, they can: ',
		answers: [
			{
				id: 412,
				text: 'Defer responsibility to Libya',
				trigger: 508,
			},
			{
				id: 413,
				text: 'Take responsibility',
				trigger: 509,
			},
			{
				id: 414,
				text: 'Neither of the above',
				trigger: 510,
			},
		],
	},
	{
		id: 307,
		image: 'CYOA-cards-307',
		imageCredit: 'Linda Flores/Flickr',
		bgColor: 'transparent',
		isEnd: false,
		// text: 'Alarm Phone is a network of activists in Europe and North Africa that people at sea can call for help. The network raises awareness about distress cases, puts pressure on states to act, and documents human rights violations. Volunteers running the hotline contact all actors in the central Mediterranean to try to prevent shipwrecks, including...',
		answers: [
			{
				id: 415,
				text: 'the Italian and Maltese RCCs',
				trigger: 306,
			},
			{
				id: 416,
				text: 'Search and rescue NGOs',
				trigger: 303,
			},
			{
				id: 417,
				text: 'Private vessels',
				trigger: 305,
			},
			{
				id: 418,
				text: 'Libyan authorities',
				trigger: 511,
			},
		],
	},
	{
		id: 308,
		image: 'CYOA-cards-308',
		imageCredit: 'Ognen Teofilovski/REUTERS',
		bgColor: 'transparent',
		isEnd: true,
		// text: '27,588 That’s how many asylum seekers and migrants have reached Europe from Libya so far this year. Even though they escaped and survived the crossing, their journeys aren’t over. People are able to apply for asylum, but new lives don’t take root overnight. Libya and the Mediterranean are behind them, but often still loom like a shadow.',
		answers: [
			{
				id: 999,
				text: 'You reached the end. Start again?',
				trigger: 0,
			},
		],
	},
	{
		id: 309,
		image: 'CYOA-cards-309',
		imageCredit: 'Miguel Virkkunen Carvalho/Flickr',
		// bgColor: 'transparent',
		isEnd: true,
		// text: '1,226: That’s how many asylum seekers and migrants are known to have died or gone missing trying to cross the central Mediterranean Sea so far this year. The true tally is higher, as some deaths are never officially recorded. Most victims are never identified, leaving behind family, friends, and communities to grapple with the loss.',
		answers: [
			{
				id: 999,
				text: 'You reached the end. Start again?',
				trigger: 0,
			},
		],
	},
	{
		id: 501,
		image: 'CYOA-cards-501',
		// imageCredit: 'Credit/TNH',
		bgColor: 'transparent',
		isEnd: false,
		// text: 'When Libyan authorities respond, they dispatch units from the Libyan Coast Guard, which has demonstrated a pattern of “reckless and violent behavior”, according to the UN High Commissioner for Human Rights, including opening fire at, ramming, and capsizing boats.',
		answers: [
			{
				id: 601,
				text: 'What happens when people are returned to Libya?',
				trigger: 503,
			},
		],
	},
	{
		id: 502,
		image: 'CYOA-cards-502',
		// imageCredit: 'Credit/TNH',
		bgColor: 'transparent',
		isEnd: false,
		// text: '“It’s a day off.” That’s how a Libyan Coast Guard official responded to Italian authorities when informed about 10 boats in distress at one point in 2017 – 126 people ended up dying. It was not a one-off incident: Libyan authorities often do not answer, or are slow to mobilise coast guard units. When Libyan authorities don’t respond, what happens next?',
		answers: [
			{
				id: 602,
				text: 'The boat is spotted by a search and rescue NGO',
				trigger: 303,
			},
			{
				id: 603,
				text: 'The boat is spotted by a private ship',
				trigger: 305,
			},
			{
				id: 604,
				text: 'The boat arrives in Italy or Malta',
				trigger: 308,
			},
			{
				id: 605,
				text: 'A shipwreck occurs',
				trigger: 309,
			},
		],
	},
	{
		id: 503,
		image: 'CYOA-cards-503',
		// bgColor: '#35b58b',
		isEnd: true,
		// text: '29,427: That’s the number of asylum seekers and migrants intercepted this year by the Libyan Coast Guard and returned to detention centres in Libya. People have little choice but to escape or bribe their way out and are often caught at sea multiple times, ending up stuck in a cycle of interception, detention, extortion, and abuse.',
		answers: [
			{
				id: 999,
				text: 'You reached the end. Start again?',
				trigger: 0,
			},
		],
	},
	{
		id: 504,
		image: 'CYOA-cards-504',
		// imageCredit: 'Credit/TNH',
		bgColor: 'transparent',
		isEnd: false,
		// text: 'After disembarkation, NGO vessels are frequently impounded by Italian authorities who cite violations of administrative regulations as reasons to hold boats, often for months. Human rights groups say seizing the vessels is part of the criminalisation of NGO rescue efforts – leading to less search and rescue capacity at sea and likely contributing to more deaths.',
		answers: [
			{
				id: 606,
				text: 'What happens to asylum seekers and migrants who reach Europe? ',
				trigger: 308,
			},
		],
	},
	{
		id: 505,
		image: 'CYOA-cards-505',
		// imageCredit: 'Credit/TNH',
		bgColor: 'transparent',
		isEnd: false,
		// text: 'There has been a sharp decline in rescues by private vessels since 2018. Because of EU states’ reluctance to assign a port for disembarkation, rescuing people causes significant financial losses; and captains fear facing legal action. When a private vessel ignores a boat in distress, four things can happen:',
		answers: [
			{
				id: 607,
				text: 'The Libyan Coast Guard intercepts the boat',
				trigger: 302,
			},
			{
				id: 608,
				text: 'A search and rescue NGO spots the boat ',
				trigger: 303,
			},
			{
				id: 609,
				text: 'The boat arrives in Italy or Malta',
				trigger: 308,
			},
			{
				id: 610,
				text: 'A shipwreck occurs',
				trigger: 309,
			},
		],
	},
	{
		id: 506,
		image: 'CYOA-cards-506',
		// bgColor: '#35b58b',
		isEnd: false,
		// text: 'EU states often prevent private vessels that rescue people from entering a European port for days or even weeks. Usually, those rescued are eventually able to disembark in an EU country – most often Italy. But the delay creates physical and psychological difficulties for asylum seekers and migrants, and significant financial losses for the private vessels.',
		answers: [
			{
				id: 611,
				text: 'What happens to asylum seekers and migrants who reach Europe?',
				trigger: 308,
			},
		],
	},
	{
		id: 507,
		image: 'CYOA-cards-507',
		// bgColor: '#35b58b',
		isEnd: false,
		// text: 'Since 2018, more than 30 private vessels have coordinated with the Libyan Coast Guard to return asylum seekers and migrants to Libya. These actions violate the principle of non-refoulement – a core element of international asylum law – by forcing people back to a country where they face human rights abuses.',
		answers: [
			{
				id: 612,
				text: 'What happens when people are returned to Libya?',
				trigger: 503,
			},
		],
	},
	{
		id: 508,
		image: 'CYOA-cards-508',
		// imageCredit: 'Credit/TNH',
		bgColor: 'transparent',
		isEnd: false,
		// text: 'When a boat is in the Libyan SAR region, Italy and Malta defer responsibility to Libya. Do Libyan authorities…',
		answers: [
			{
				id: 613,
				text: 'Respond? ',
				trigger: 501,
			},
			{
				id: 614,
				text: 'Not respond? ',
				trigger: 502,
			},
		],
	},
	{
		id: 509,
		image: 'CYOA-cards-509',
		// imageCredit: 'Credit/TNH',
		bgColor: 'transparent',
		isEnd: false,
		// text: 'If the boat is in the Italian or Maltese SAR region, they are supposed to take responsibility for responding to the case. If either country takes responsibility, they can...',
		answers: [
			{
				id: 615,
				text: 'Rescue the boat and bring people to Italy or Malta',
				trigger: 308,
			},
			{
				id: 616,
				text: 'Ask a private vessel to provide assistance',
				trigger: 305,
			},
		],
	},
	{
		id: 510,
		image: 'CYOA-cards-510',
		// imageCredit: 'Credit/TNH',
		bgColor: 'transparent',
		isEnd: false,
		// text: 'The Italian and Maltese SAR regions overlap in areas, enabling both countries to claim the other is responsible and leading to significant delays in rescues. Malta’s RCC is also often unresponsive when contacted by NGOs and does not initiate rescues unless Maltese authorities determine people are in imminent danger of death. What happens when Italian and Maltese authorities don’t take responsibility? ',
		answers: [
			{
				id: 617,
				text: 'A shipwreck occurs',
				trigger: 309,
			},
			{
				id: 618,
				text: 'A search and rescue NGO spots the boat ',
				trigger: 303,
			},
			{
				id: 619,
				text: 'The boat arrives on its own to Italy or Malta',
				trigger: 308,
			},
		],
	},
	{
		id: 511,
		image: 'CYOA-cards-511',
		// imageCredit: 'Credit/TNH',
		bgColor: 'transparent',
		isEnd: false,
		// text: 'When contacted by Alarm Phone, do the Libyan authorities...',
		answers: [
			{
				id: 619,
				text: 'Respond?',
				trigger: 501,
			},
			{
				id: 620,
				text: 'Not respond?',
				trigger: 502,
			},
		],
	},
]

export default data
